import { DESKTOP_BREAKPOINT, TABLET_BREAKPOINT } from '@maverick/utils';
import styled from 'styled-components';

export const Container = styled.section`
	padding: 0;
	background-color: ${({ theme }) => theme.orderUi.specificStyle.cardBackgroundColor};

	@media screen and (max-width: ${TABLET_BREAKPOINT}px) {
		margin-bottom: 30px;
	}
	@media screen and (min-width: ${DESKTOP_BREAKPOINT}px) {
		padding: 16px 32px;
	}
`;

export const OrderInformation = styled.div``;

export const NutritionalInformation = styled.div``;

export const Information = styled.div`
	display: flex;
	gap: 16px;
	flex-direction: column;
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const InfoSubtitle = styled.div`
	${({ theme }) => theme.orderUi.typography.headline.variant};
	text-transform: ${({ theme }) => theme.orderUi.specificStyle.titleLetterCase};
	color: ${({ theme }) => theme.orderUi.specificStyle.titleColor};
`;

export const InfoDescription = styled.div`
	${({ theme }) => theme.orderUi.typography.body.bodySmall}
	color: ${({ theme }) => theme.orderUi.colors.tertiary.dark};

	@media screen and (min-width: ${TABLET_BREAKPOINT}px) {
		${({ theme }) => theme.orderUi.typography.body.body}
	}
`;

export const Line = styled.hr`
	background-color: ${({ theme }) => theme.orderUi.specificStyle.nutritionalInfoLine};
	border: none;
	height: 1.5px;
	margin: 16px 0;
`;

export const ButtonAdditional = styled.div`
	display: flex;
	gap: 16px;
`;

export const IconArrow = styled.div`
	color: ${({ theme }) => theme.orderUi.colors.primary.medium};
	width: 8px;
	cursor: pointer;

	:hover {
		color: ${({ theme }) => theme.orderUi.colors.primary.light};
	}
`;
