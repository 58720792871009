import { FC, useEffect, useState } from 'react';
import * as Styled from './HeaderInteraction.styled';
import { ReduxProps } from './index';
import { Drawer, Icon, AnimatedIcon, Loading } from '@maverick/ui';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useRedirect } from '../../hooks';
import { OrderSummary } from '../../../features/Checkout/views/OrderSummary';
import { DESKTOP_BREAKPOINT, handleKeyDown } from '../../../utils/GeneralUtils';
import { useIsMobile } from '@maverick/hooks';
import { GaEvent } from '@maverick/utils';
import { useTheme } from 'styled-components';
import { Config } from '../../../Config';
import { Tooltip } from '../Tooltip';

export interface HeaderInteractionProps extends ReduxProps {
	transformTooltip?: boolean;
}

export const HeaderInteraction: FC<HeaderInteractionProps> = ({
	basket,
	showSummary,
	setShowSummary,
	setRedirectRoute,
	setLoading,
	userLogged,
	setUserLogged,
	transformTooltip,
}) => {
	const { orderUi } = useTheme();
	const redirect = useRedirect();
	const location = useLocation();
	const [showedIcon, setShowedIcon] = useState<JSX.Element>(
		<Icon icon={orderUi.icons.iconUserHeader} customSize testId='user-icon' ariaLabel='view profile' />
	);
	const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
	const isMobile = useIsMobile(DESKTOP_BREAKPOINT);
	const shouldShowSummary = showSummary && !!basket?.products.length;

	useEffect(() => {
		if (isAuthenticated) {
			if (userLogged !== isAuthenticated) {
				setShowedIcon(
					<AnimatedIcon
						icon={orderUi.icons.iconUserLoggedAnimation}
						onAnimationComplete={handleAnimationComplete}
						testId='user-icon'
					/>
				);
				setUserLogged(isAuthenticated);
			} else {
				setShowedIcon(
					<Icon
						icon={orderUi.icons.iconUserLoggedHeader}
						ariaLabel='view profile'
						customSize
						testId='user-icon'
					/>
				);
			}
		}
	}, [isAuthenticated]);

	const handleAnimationComplete = () => {
		setShowedIcon(
			<Icon icon={orderUi.icons.iconUserLoggedHeader} ariaLabel='view profile' customSize testId='user-icon' />
		);
	};

	const handleLogin = (force?: boolean) => {
		let userPages = ['/recent-orders', '/my-account', '/faves', '/my-rewards', '/my-activity'];

		if (!force && isLoading) {
			return;
		}

		if (!isAuthenticated) {
			GaTopNavigationEvent('Login');
			setRedirectRoute(location.pathname);
			loginWithRedirect({ source: Config.Source });
		}

		if (isAuthenticated) {
			if (!userPages.includes(location.pathname)) {
				setRedirectRoute(location.pathname);
			}

			redirect('/my-account');
		}
	};

	const GaTopNavigationEvent = (label: string) => {
		GaEvent.TopNavigation(label);
	};

	const handleShowSummary = () => {
		if (basket?.products.length !== 0) {
			GaTopNavigationEvent('Cart');
			setShowSummary(true);
			setLoading(true);
		}
	};

	const showBasket = !!basket && basket.products.length > 0 && location.pathname !== '/checkout';
	const basketBadge = !!basket?.products.length;
	const drawerWidth = isMobile ? DESKTOP_BREAKPOINT : 466;
	return (
		<>
			<Styled.Container>
				<Styled.Icons>
					<Tooltip
						description={isAuthenticated ? `Logged In` : `Logged Out`}
						id='user-icon-tooltip'
						variant='small'
						transformTooltip={transformTooltip}
					>
						<Styled.UserAccount 
							tabIndex={0}
							onClick={() => {
								handleLogin();
							}}
							aria-label={`User Account - ${isAuthenticated ? `Logged In` : `Logged Out`}` }
							onKeyDown={(ev) => handleKeyDown(ev, handleLogin)}
						>
							<Styled.AnimatedIconContainer isLoading={isLoading}>
								{isLoading ? <Loading size={20} color='#fff' /> : showedIcon}
							</Styled.AnimatedIconContainer>
						</Styled.UserAccount>
					</Tooltip>
					{showBasket && (
						<Styled.Button
							onClick={handleShowSummary}
							aria-label={`Basket${
								basket?.products.length !== 0
									? ` ${basket?.products.length} ${basket?.products.length === 1 ? 'item' : 'items'}`
									: ''
							}`}
							onKeyDown={(ev) => handleKeyDown(ev, handleShowSummary)}
						>
							<Styled.Icon data-testid='basket-icon'>
								<Icon icon={orderUi.icons.iconBasketHeader} ariaLabel='view basket' customSize />
								{basketBadge && (
									<Styled.BasketBadge data-testid='basket-badge'>
										{basket?.products.length}
									</Styled.BasketBadge>
								)}
							</Styled.Icon>
						</Styled.Button>
					)}
				</Styled.Icons>
			</Styled.Container>

			{/* TODO see if this code is necessary */}
			<Drawer
				isOpen={shouldShowSummary}
				onClose={() => setShowSummary(false)}
				hideScrollbar
				width={drawerWidth}
				maxWidth={100}
			>
				{shouldShowSummary && <OrderSummary onClose={() => setShowSummary(false)}></OrderSummary>}
			</Drawer>
		</>
	);
};
