import { RewardWallet } from '@maverick/entity';
import { FC, useEffect, useState } from 'react';
import { handleKeyDown } from '../../../utils/GeneralUtils';
import * as Styled from './RewardsButton.styled';
import { Button } from '@maverick/ui';
import { getDateWithoutTimezone } from '@maverick/utils';

export enum RewardsButtonVariants {
	Primary = 'primary',
	Secondary = 'secondary',
	Disabled = 'disabled',
}

export interface RewardsButtonProps {
	variant?: RewardsButtonVariants;
	disabledCard?: boolean;
	disabled?: boolean;
	reward: RewardWallet;
	selected: boolean;
	id: number;
	onClick: () => void;
}

export const RewardsButton: FC<RewardsButtonProps> = ({
	variant = RewardsButtonVariants.Primary,
	disabledCard = false,
	disabled,
	reward,
	selected,
	onClick,
	id,
}) => {
	const isVariantPrimary = variant === RewardsButtonVariants.Primary;
	const rewardsPrice =
		reward.rewardValue === null || reward.rewardValue === 0 ? 'FREE' : '$' + reward.rewardValue?.toFixed(2);

	const [validAt, setValidAt] = useState<string>('');

	useEffect(() => {
		if (!reward.validAt || reward.validAt.length === 0) {
			return;
		}
		if (reward.validAt[0] === 'All Brands') {
			setValidAt('Valid for all Dine Rewards brands');
			return;
		}
		setValidAt(`Valid at ${reward.validAt.join(', ')}`);
	}, [reward.validAt]);

	const expiresAt = getDateWithoutTimezone(reward.expirationDate.toString());
	return (
		<Styled.Container>
			<Styled.Rewards
				variant={variant}
				disabledCard={disabledCard}
				role={isVariantPrimary ? 'option' : 'button'}
				tabIndex={isVariantPrimary ? 0 : -1}
				aria-label={`${reward.name} expires at ${expiresAt} redeem`}
				onKeyDown={(ev) => handleKeyDown(ev, onClick)}
				selected={selected}
				onClick={isVariantPrimary ? onClick : undefined}
				id={`rewards-${id}`}
				data-testid={`rewards-${reward.id}`}
			>
				<Styled.TextItems variant={variant}>
					<Styled.OffRewards
						variant={variant}
						title={reward.name}
						tabIndex={-1}
						aria-hidden
						id={`rewards-title-${id}`}
						data-testid={`rewards-title-${reward.id}`}
					>
						{reward.name}
					</Styled.OffRewards>
					{validAt && (
						<Styled.ValidAt
							title={validAt}
							tabIndex={-1}
							aria-hidden
							id={`rewards-valid-at-${id}`}
							data-testid={`rewards-valid-at-${reward.id}`}
						>
							{validAt}
						</Styled.ValidAt>
					)}
					<Styled.Expires
						aria-hidden
						tabIndex={-1}
						id={`rewards-expiration-${id}`}
						data-testid={`rewards-expiration-${reward.id}`}
					>
						Expires {expiresAt}
					</Styled.Expires>
				</Styled.TextItems>
				{isVariantPrimary ? (
					<Styled.Value
						disabledCard={disabledCard}
						id={`rewards-value-${id}`}
						data-testid={`rewards-value-${reward.id}`}
					>
						{rewardsPrice}
					</Styled.Value>
				) : (
					<Styled.RedeemButton>
						<Button
							disabled={disabled}
							data-testid={`redeem-reward-${reward.id}`}
							text='Redeem'
							variant='terciary'
							onClick={onClick}
							id={`redeem-reward`}
						/>
					</Styled.RedeemButton>
				)}
			</Styled.Rewards>
		</Styled.Container>
	);
};
